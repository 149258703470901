import Section from "../shared/section";
import { useState, useEffect, useRef } from "react";
import LightGallery from "lightgallery/react";
import { Images } from "./work/images";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const buttonStyle = {
  display: "block",
  margin: "0 auto", // Center horizontally
  marginTop: "20px", // Add some top margin for spacing
};

const Content = () => {
  return <>Click a picture to open the gallery.</>;
};

const GetImages = (max: number) => {
  return Images.slice(0, max).map((image) => (
    <a key={image.alt} href={image.src}>
      <img alt={image.alt} src={image.src} />
    </a>
  ));
};

const Gallery = () => {
  const [showImages, setShowImages] = useState(false);

  const onInit = () => {
    console.log("lightGallery has been initialized");
  };

  const loadImages = () => {
    setShowImages(true);
  };

  const closeGallery = () => {
    setShowImages(false);
  };

  const renderImagesOrButton = () => {
    if (showImages) {
      return (
        <>
          <LightGallery
            elementClassNames="theGalleryWrapper"
            onInit={onInit}
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
            mode="lg-fade"
            thumbnail={true}
          >
            {GetImages(27)}
          </LightGallery>
          <button onClick={closeGallery} style={buttonStyle}>
            Close Gallery
          </button>
        </>
      );
    } else {
      return (
        <button onClick={loadImages} style={buttonStyle}>
          Load Images
        </button>
      );
    }
  };

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      if (window.innerWidth > 768) {
        setShowImages(true);
      }
    }
  }, [containerRef]);

  return (
    <div className="theGallery" ref={containerRef}>
      {renderImagesOrButton()}
    </div>
  );
};

const Two = () => {
  return (
    <Section
      id={"work"}
      sectionStyle={"main style3 primary"}
      contentStyle={"content1"}
      header="Our Work"
      content={<Content />}
      footer={"display:none"}
      footerContent={<></>}
      element={<Gallery />}
      showVid={false}
    />
  );
};

export default Two;
