import { Link } from "react-scroll";

const Header = () => {
  return (
    <header id="header">
      <Link to="intro" spy={true} smooth={true} offset={0} duration={1500}>
        <h1>Divine Royalty Events</h1>
      </Link>
      <nav>
        <ul>
          <li>
            <Link
              to="intro"
              spy={true}
              smooth={true}
              offset={0}
              duration={1500}
            >
              Intro
            </Link>
          </li>
          <li>
            <Link to="one" spy={true} smooth={true} offset={0} duration={1500}>
              Our Vision
            </Link>
          </li>
          {/* <li>
          <Link to='two' spy={true} smooth={true} offset={-20} duration={1500}>
            Who I Am</Link>
        </li> */}
          <li>
            <Link
              to="work"
              spy={true}
              smooth={true}
              offset={50}
              duration={1500}
            >
              Our Work
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={50}
              duration={1500}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
