import React from "react";
import { page } from "../schema/page";
import VideoWithStatusText from "../shared/status";

const Section: React.FC<page> = (props: page) => {
  let desktopVidsrc =
    "https://divineroyaltyevents.com/images/vids/Wedding1.mp4";
  let mobileSrc =
    "https://divineroyaltyevents.com/images/vids/Wedding1_mobile.mp4";
  let loadPic = "../images/intro.jpg";

  return (
    <section id={props.id} className={props.sectionStyle}>
      {props.showVid ? (
        <VideoWithStatusText
          desktopSrc={desktopVidsrc}
          mobileSrc={mobileSrc}
          poster={loadPic}
        />
      ) : null}
      <div className={props.contentStyle}>
        <header>
          <h2>{props.header}</h2>
        </header>
        <p>{props.content}</p>
        <footer className={props.footer}>{props.footerContent}</footer>
        {/* <div style={{ maxWidth: "100%", maxHeight: "100%" }}> */}
        {props.element}
        {/* </div> */}
      </div>
    </section>
  );
};

export default Section;
