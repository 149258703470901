import { Link } from "react-scroll";
import Section from "../shared/section";

const Content = () => {
  return (
    <>
      {" "}
      Welcome to <strong>Divine Royalty Events!</strong> Book Us Today!
      {/* <br />
      and released for free under the{" "}
      <a href="https://html5up.net/license">
        Creative Commons Attribution license
      </a> */}
    </>
  );
};

const Intro = () => {
  return (
    <Section
      id={"intro"}
      sectionStyle={"main style1 dark fullscreen"}
      contentStyle={"content"}
      header="Welcome!"
      content={<Content />}
      footer={""}
      footerContent={
        <>
          <Link
            to="one"
            spy={true}
            smooth={true}
            offset={0}
            duration={1500}
            className="button style2 down"
          >
            More
          </Link>
        </>
      }
      showVid={true}
    />
  );
};

export default Intro;
