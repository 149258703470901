import { DirectLine } from "botframework-directlinejs";
import React, { useEffect, useState } from "react";
import ReactWebChat from "botframework-webchat";

interface WebChatProps {
  secret: string;
  userID: string;
}

const customStyleOptions = {
  // Other existing style options...
  sendBoxBorderBottom: "solid 1px",
};

const FallBack = () => {
  return (
    <p>
      Fill out the{" "}
      <b>
        <a href="https://www.surveymonkey.com/r/7LWRDDW?fbclid=IwAR1jQCv-TDxi4srlrZbjJkwSMXnM4Q38ldnSMuxWmMKbANDJ8ToUZ7Yjo5g">
          Questionnaire
        </a>{" "}
      </b>{" "}
      to get started! If you have additional questions, contact us below.
    </p>
  );
};

const DRBot = ({ secret, userID }: WebChatProps) => {
  const [token, setToken] = useState<string>();
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    // Fetch the token from the Direct Line API using the secret
    fetch("https://directline.botframework.com/v3/directline/tokens/generate", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${secret}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          // Handle any API error by throwing an error with the status text
          throw new Error(`${res.status} ${res.statusText}`);
        }
        return res.json();
      })
      .then((data) => {
        // Set the token state
        setToken(data.token);
      })
      .catch((err) => {
        // Handle other errors (e.g., connection timeout)
        console.error(err);
        setHasError(true);
      });
  }, [secret]);

  // Render Web Chat only when the token is available and no error has occurred
  return !hasError ? (
    token ? (
      <ReactWebChat
        directLine={new DirectLine({ token })}
        userID={userID}
        styleOptions={customStyleOptions}
      />
    ) : (
      <div>Loading...</div>
    )
  ) : (
    <FallBack />
  );
};

export default DRBot;
