import { Link as RcdomLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa";

const Footer = () => {
  return (
    <footer id="footer">
      <ul className="icons">
        <li>
          <RcdomLink
            target="_blank"
            to="https://www.tiktok.com/@divineroyaltyevents"
          >
            <FaIcons.FaTiktok />
          </RcdomLink>
        </li>
        <li>
          <RcdomLink
            target="_blank"
            to="https://www.instagram.com/divine_royalty21/"
          >
            <FaIcons.FaInstagram />
          </RcdomLink>
        </li>
        <li>
          <RcdomLink
            target="_blank"
            to="https://www.facebook.com/DivineRoyaltyEvents"
          >
            <FaIcons.FaFacebookF />
          </RcdomLink>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
