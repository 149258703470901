import ReactDOM from "react-dom/client";
import React from "react";
import "./index.css";
import App from "./App";
//import WeddingMock from "./components/pages/wedding";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//import Rent from "./components/pages/rent";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
  // <BrowserRouter>
  //   <Routes>
  //     <Route path="/" element={<App />} />
  //     <Route path="/mock" element={<WeddingMock />} />
  //     <Route path="/rent" element={<Rent />} />
  //   </Routes>
  // </BrowserRouter>
);
