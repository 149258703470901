export type Image = {
  alt: string;
  src: string;
};

export const Images: Image[] = [
  { alt: "1", src: `${process.env.PUBLIC_URL + "/images/gallery/101.jpg"}` },
  { alt: "6", src: `${process.env.PUBLIC_URL + "/images/gallery/113.jpg"}` },
  { alt: "2", src: `${process.env.PUBLIC_URL + "/images/gallery/102.jpg"}` },
  { alt: "3", src: `${process.env.PUBLIC_URL + "/images/gallery/103.jpg"}` },
  { alt: "4", src: `${process.env.PUBLIC_URL + "/images/gallery/105.jpg"}` },
  { alt: "5", src: `${process.env.PUBLIC_URL + "/images/gallery/106.jpg"}` },

  { alt: "7", src: `${process.env.PUBLIC_URL + "/images/gallery/108.jpg"}` },
  { alt: "8", src: `${process.env.PUBLIC_URL + "/images/gallery/109.jpg"}` },
  // { alt: "9", src: `${process.env.PUBLIC_URL + "/images/gallery/51.jpg"}` },
  // { alt: "10", src: `${process.env.PUBLIC_URL + "/images/gallery/52.jpg"}` },
  // { alt: "11", src: `${process.env.PUBLIC_URL + "/images/gallery/53.jpg"}` },
  { alt: "12", src: `${process.env.PUBLIC_URL + "/images/gallery/54.jpg"}` },
  { alt: "13", src: `${process.env.PUBLIC_URL + "/images/gallery/55.jpg"}` },
  { alt: "14", src: `${process.env.PUBLIC_URL + "/images/gallery/56.jpg"}` },
  { alt: "15", src: `${process.env.PUBLIC_URL + "/images/gallery/57.jpg"}` },
  { alt: "16", src: `${process.env.PUBLIC_URL + "/images/gallery/58.jpg"}` },
  { alt: "17", src: `${process.env.PUBLIC_URL + "/images/gallery/59.jpg"}` },
  { alt: "18", src: `${process.env.PUBLIC_URL + "/images/gallery/60.jpg"}` },
  { alt: "19", src: `${process.env.PUBLIC_URL + "/images/gallery/61.jpg"}` },
  { alt: "20", src: `${process.env.PUBLIC_URL + "/images/gallery/62.jpg"}` },
  { alt: "21", src: `${process.env.PUBLIC_URL + "/images/gallery/64.jpg"}` },
  { alt: "22", src: `${process.env.PUBLIC_URL + "/images/gallery/65.jpg"}` },
  { alt: "23", src: `${process.env.PUBLIC_URL + "/images/gallery/10.jpg"}` },
  { alt: "24", src: `${process.env.PUBLIC_URL + "/images/gallery/16.jpg"}` },
  { alt: "25", src: `${process.env.PUBLIC_URL + "/images/gallery/111.jpg"}` },
  { alt: "26", src: `${process.env.PUBLIC_URL + "/images/gallery/112.jpg"}` },
];

//export default Images;
