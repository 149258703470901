import Section from "../shared/section";
import { Link } from "react-scroll";

const Content = () => {
  return (
    <>
      Divine Royalty mission is to create everlasting memorable events. We want
      our clients to be filled with joy as we bring their vision to life! Our
      prayer is for you to have a healthy, loving and lasting marriage.
      <br />
      <i>Ephesians 4-2:3</i>
    </>
  );
};

const One = () => {
  return (
    <Section
      id={"one"}
      sectionStyle={"main style2 right dark fullscreen"}
      contentStyle={"content box style2"}
      header="Our Vision"
      content={<Content />}
      footer={""}
      footerContent={
        <>
          <Link
            to="work"
            spy={true}
            smooth={true}
            offset={0}
            duration={1500}
            className="button down"
          >
            More
          </Link>
        </>
      }
      showVid={false}
    />
  );
};

export default One;
