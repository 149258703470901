// import styles
import "./App.css";
import "../src/assets/sass/main.scss";
import "../src/assets/css/main.css";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import pages
import Header from "./components/shared/header";
import Intro from "./components/pages/intro";
import One from "./components/pages/one";
import Work from "./components/pages/work";
import Contact from "./components/pages/contact";
import Footer from "./components/shared/footer";

// If you want you can use SCSS instead of css
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";

function App() {
  return (
    <div className="App is-preload">
      <Header />
      <Intro />
      <One />
      <Work />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
