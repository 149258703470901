import React from "react";

interface VideoWithStatusTextProps {
  desktopSrc: string;
  mobileSrc: string;
  poster: string;
}

const VideoWithStatusText: React.FC<VideoWithStatusTextProps> = ({
  desktopSrc,
  mobileSrc,
  poster,
}) => {
  // Detect if user agent is a mobile device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Choose the appropriate video source based on device type
  const src = isMobile ? mobileSrc : desktopSrc;

  return (
    <video poster={poster} autoPlay loop muted id="vid" playsInline>
      <source src={src} type="video/mp4"></source>
    </video>
  );
};

export default VideoWithStatusText;
