import React from "react";
import Section from "../shared/section";
import emailjs from "@emailjs/browser";
import DRBot from "../shared/drbot";
import { v4 as uuidv4 } from "uuid";

const Content = () => {
  return (
    <>
      <b>Questions? Ask away!</b>
    </>
  );
};

function generateRandomGuid(): string {
  return uuidv4();
}

const Box = () => {
  const ref = React.useRef<HTMLFormElement>(null);
  const sendEmail = (e: React.ChangeEvent<HTMLFormElement>): void => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_z2yeeau",
        "template_mep1dhv",
        e.currentTarget,
        "0xkcdzxHqz_cf3Zsb"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message was sent.");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.currentTarget.reset();
  };

  return (
    <div className="box">
      <div>
        <br />
        <h1>Email Us Today!</h1>
        <br />
      </div>
      <form onSubmit={sendEmail} ref={ref} method="post" action="#">
        <div className="fields">
          <div className="field half">
            <input type="text" name="user_name" placeholder="Name" />
          </div>
          <div className="field half">
            <input type="email" name="user_email" placeholder="Email" />
          </div>
          <div className="field">
            <textarea name="message" placeholder="Message"></textarea>
          </div>
        </div>
        <ul className="actions special">
          <li>
            <input type="submit" value="Send Message" />
            {/* /{botDetected? (<BotCheck />):} */}
          </li>
        </ul>
      </form>
    </div>
  );
};

const Contact = () => {
  return (
    <Section
      id="contact"
      sectionStyle={"main style3 secondary"}
      contentStyle={"content"}
      header="Say Hello."
      content={<Content />}
      footer={"display:block"}
      footerContent={
        <DRBot
          secret={process.env.REACT_APP_SECRET}
          userID={`user-${generateRandomGuid()}`}
        />
      }
      element={<Box />}
      showVid={false}
    />
  );
};

export default Contact;
